import React from 'react';

import Layout from '../layout';
import SEO from '../components/seo';

import Container from '../components/container';

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page Not Found" />

      <Container>
        <h1 className="text-2xl font-bold leading-tight tracking-tighter xl:text-3xl">
          Page Not Found
        </h1>

        <p className="mt-4">Not exactly sure what happened ...</p>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
